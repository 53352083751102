import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import et from 'src/locale/et.json';
import en from 'src/locale/en.json';
import ru from 'src/locale/ru.json';

const resources = {
  en: {
    translation: en,
  },
  et: {
    translation: et,
  },
  ru: {
    translation: ru,
  },
};

const localeValue = window.localStorage.getItem('dashboardLanguage');

i18n.use(initReactI18next).init({
  resources,
  lng: localeValue || 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
