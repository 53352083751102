import {
  ProductCategoryJoinInfoDto,
  ProductCategoryJoinInfoCreateDto,
  ProductCategoryJoinInfoUpdateDto,
  ProductCategoryTranslationDto,
  ProductCategoryTranslationCreateDto,
  ProductCategoryTranslationUpdateDto,
} from '../../../../types/generated';

import BaseCrudApiModule from '../../../baseCrud';

class ProductCategoryApi extends BaseCrudApiModule<
  ProductCategoryJoinInfoDto,
  ProductCategoryJoinInfoCreateDto,
  ProductCategoryJoinInfoUpdateDto
> {
  protected baseRoute = '/product-categories';

  public getAllTranslations() {
    return this._get<ProductCategoryTranslationDto[]>('product-category-translations');
  }

  public getTranslations(id: string | number) {
    return this._get<ProductCategoryTranslationDto[]>(`${this.baseRoute}/${id}/translations`);
  }

  public setTranslations(translations: (ProductCategoryTranslationCreateDto | ProductCategoryTranslationUpdateDto)[]) {
    return this._post<ProductCategoryTranslationDto[]>('product-category-translations', translations);
  }
}

export default ProductCategoryApi;
