import { IApiFilter, IApiPage, IApiSort } from 'src/types/interfaces';

export enum FilterOperators {
  equals = '=',
  contains = 'like',
  includes = 'in',
  between = 'between',
}
export function serializePagination(page: IApiPage): {
  'page[size]'?: string;
  'page[number]'?: string;
} {
  const { size, number } = page || {};
  const paginationParams = {};
  if (size) paginationParams['page[size]'] = size;
  if (number) paginationParams['page[number]'] = number;
  return paginationParams;
}
export function serializeFilters(filters: IApiFilter[] = []): { [key: string]: string } {
  return filters.reduce((filterParams, apiFilter) => {
    const { field, operator = '=', value } = apiFilter;
    const filterParamName = `filter[${field}][${operator}]`;
    if (operator === FilterOperators.includes || operator === FilterOperators.between) {
      value.split(',').forEach((val, i) => {
        filterParams[`${filterParamName}[${i}]`] = val;
      });
      return filterParams;
    }
    if (operator === FilterOperators.contains) {
      filterParams[filterParamName] = `%${value}%`;
      return filterParams;
    }
    filterParams[filterParamName] = value;
    return filterParams;
  }, {});
}
export function serializeSorts(sorts: IApiSort[] = []): { [key: string]: string } {
  return sorts.reduce((sortParams, apiSort) => {
    const { field, ascending } = apiSort;
    const sortParamName = `sort[${field}]`;
    sortParams[sortParamName] = ascending ? 'asc' : 'desc';
    return sortParams;
  }, {});
}
export function createApiFilter(
  field: string,
  value: string,
  operator: FilterOperators = FilterOperators.equals
): IApiFilter {
  return { field, value, operator };
}
export function createApiSort(field: string, ascending: boolean = true): IApiSort {
  return {
    field,
    ascending,
  };
}
