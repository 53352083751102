import {
  ProductVariantJoinInfoDto,
  ProductVariantJoinInfoCreateDto,
  ProductVariantJoinInfoUpdateDto,
  ProductVariantTranslationDto,
  ProductVariantTranslationCreateDto,
  ProductVariantTranslationUpdateDto,
} from 'src/types/generated';

import BaseCrudApiModule from 'src/api/baseCrud';

class ProductVariantApi extends BaseCrudApiModule<
  ProductVariantJoinInfoDto,
  ProductVariantJoinInfoCreateDto,
  ProductVariantJoinInfoUpdateDto
> {
  protected baseRoute = '/product-variants';

  public getAllTranslations() {
    return this._get<ProductVariantTranslationDto[]>('product-variant-translations');
  }

  public getTranslations(id: string | number) {
    return this._get<ProductVariantTranslationDto[]>(`${this.baseRoute}/${id}/translations`);
  }

  public setTranslations(translations: (ProductVariantTranslationCreateDto | ProductVariantTranslationUpdateDto)[]) {
    return this._post<ProductVariantTranslationDto[]>('product-variant-translations', translations);
  }
}

export default ProductVariantApi;
