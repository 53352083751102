import { isEmpty, omitBy } from 'lodash';

import { OrderJoinGeneralDto, OrderJoinDetailsDto } from 'src/types/generated';
import BaseCrudApiModule from 'src/api/baseCrud';
import { FileAction } from 'src/types/interfaces';

interface IGetInvoicePdf {
  orderId: number;
  fileName: string;
  action: FileAction;
  data: {
    recipientName: string;
    recipientEmail: string;
    recipientPhoneNumber: string;
    languageId: string;
  };
}

class OrderApi extends BaseCrudApiModule<OrderJoinGeneralDto> {
  protected baseRoute = '/orders';

  public getOrder(id: string | number) {
    return this._get<OrderJoinDetailsDto>(`${this.baseRoute}/${id}`);
  }

  public async getInvoicePdf(model: IGetInvoicePdf) {
    const params: Record<string, string> = omitBy(
      {
        recipientName: model.data.recipientName,
        recipientEmail: model.data.recipientEmail,
        recipientPhoneNumber: model.data.recipientPhoneNumber,
        languageId: model.data.languageId,
      },
      (value) => !value
    );
    const queryString = !isEmpty(params) ? `?${new URLSearchParams(params).toString()}` : '';
    const invoicePdfUrl = `${this.baseRoute}/${model.orderId}/invoice-pdf${queryString}`;

    if (model.action === FileAction.Download) {
      await this.downloadFile(invoicePdfUrl, model.fileName);
    }

    if (model.action === FileAction.Share) {
      await this.shareFile(invoicePdfUrl, model.fileName, 'application/pdf');
    }
  }
}

export default OrderApi;
