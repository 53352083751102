import {
  SalePointWorkingDayDto,
  SalePointAvailableTimeDto,
  SalePointAvailableTimeCreateUpdateDto,
  SalePointDto,
  SalePointCreateDto,
  SalePointUpdateDto,
  SalepointProductCreateUpdateDto,
  SalepointProductDto,
} from 'src/types/generated';

import BaseCrudApiModule from '../../baseCrud';
import { IRequestParameters } from '../../base';
import { FilterOperators, serializeFilters } from '../../utils';

class SalePointApi extends BaseCrudApiModule<SalePointDto, SalePointCreateDto, SalePointUpdateDto> {
  protected baseRoute = '/sale-points';

  public getWorkingDays(id: string) {
    return this._get<SalePointWorkingDayDto[]>(`${this.baseRoute}/${id}/working-days`);
  }

  public setWorkingDays(body: SalePointWorkingDayDto[]) {
    return this._post('/sale-point-working-days', body);
  }

  public getAvailableTimes(id: string, year?: number) {
    let params: IRequestParameters = {};
    if (year) {
      params = serializeFilters([{
        field: 'year',
        value: String(year),
        operator: FilterOperators.equals,
      }]);
    }
    return this._get<SalePointAvailableTimeDto[]>(`${this.baseRoute}/${id}/available-times`, { params });
  }

  public setAvailableTimes(body: SalePointAvailableTimeCreateUpdateDto[]) {
    return this._post('/sale-point-available-times', body);
  }

  public deleteAvailableTimes(id: string) {
    return this._delete(`/sale-point-available-times/${id}`);
  }

  public getSalepointProducts(salepointId: number) {
    return this._get<SalepointProductDto[]>(`/sale-points/${salepointId}/products`);
  }

  public setSalepointProducts(salepointId: number, data: SalepointProductCreateUpdateDto[]) {
    return this._post(`${this.baseRoute}/${salepointId}/products`, data);
  }
}

export default SalePointApi;
