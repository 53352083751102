import {
  ProductVariantGroupDto,
  ProductVariantGroupCreateDto,
  ProductVariantGroupUpdateDto,
} from 'src/types/generated';

import BaseCrudApiModule from 'src/api/baseCrud';

class ProductVariantGroupApi extends BaseCrudApiModule<
  ProductVariantGroupDto,
  ProductVariantGroupCreateDto,
  ProductVariantGroupUpdateDto
> {
  protected baseRoute = '/product-variant-groups';
}

export default ProductVariantGroupApi;
