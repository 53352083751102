import {
  CampaignDiscountProductDto,
  CampaignImageAdditionalData,
  CampaignJoinInfoCreateDto,
  CampaignJoinInfoDto,
  CampaignJoinInfoUpdateDto,
  CampaignTranslationCreateDto,
  CampaignTranslationDto,
  CampaignTranslationUpdateDto,
} from 'src/types/generated';

import BaseCrudApiModule from 'src/api/baseCrud';

class CampaignApi extends BaseCrudApiModule<CampaignJoinInfoDto, CampaignJoinInfoCreateDto, CampaignJoinInfoUpdateDto> {
  protected baseRoute = '/campaigns';

  public setImages(campaignId: number, files: File[], filesData: CampaignImageAdditionalData[]) {
    const formData = new FormData();
    files.forEach((x) => {
      formData.append('images', x);
    });
    formData.set('campaignId', campaignId.toString());
    formData.set('filesData', JSON.stringify(filesData));
    return this._post('campaign-images', formData);
  }

  public deleteImages(campaignId: number, languageId: number) {
    return this._delete(`campaign-images/${campaignId}/${languageId}`);
  }

  public getTranslations(id: number) {
    return this._get<CampaignTranslationDto[]>(`${this.baseRoute}/${id}/translations`);
  }

  public setTranslations(translations: (CampaignTranslationUpdateDto | CampaignTranslationCreateDto)[]) {
    return this._post<CampaignTranslationDto[]>('campaign-translations', translations);
  }

  public getDiscountProducts() {
    return this._get<CampaignDiscountProductDto[]>(`${this.baseRoute}/discount-products`);
  }
}

export default CampaignApi;
