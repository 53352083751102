import { FC, ReactNode, useState } from 'react';
import { UserRoleEnum } from 'src/types/interfaces';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { Navigate, useLocation } from 'react-router-dom';

interface IProps {
  children: ReactNode;
  roles: UserRoleEnum[];
}

const RoleGuard: FC<IProps> = (props) => {
  const { user } = useAuth();
  const { roles, children } = props;
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  const hasPermission = roles.some((role) => role === user.role);
  if (!hasPermission) {
    return <Navigate to="/" />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

RoleGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.array.isRequired,
};

export default RoleGuard;
