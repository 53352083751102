import {
  LanguageCreateDto,
  LanguageDto,
  LanguageUpdateDto,
  EmailTemplateDto,
  EmailTemplateInsertDto,
} from 'src/types/generated';
import BaseCrudApiModule from '../../baseCrud';

class LanguageApi extends BaseCrudApiModule<LanguageDto, LanguageCreateDto, LanguageUpdateDto> {
  protected baseRoute = '/languages';

  public getEmailTemplates(id: string | number) {
    return this._get<EmailTemplateDto[]>(`${this.baseRoute}/${id}/email-templates`);
  }

  public setEmailTemplates(emailTemplates: EmailTemplateInsertDto[]) {
    return this._post<EmailTemplateDto[]>('email-templates', emailTemplates);
  }
}

export default LanguageApi;
