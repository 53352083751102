import API from './api';
import { AxiosError, AxiosRequestConfig } from 'axios';
import toast from 'react-hot-toast';
import i18n from '../i18n';

import AuthApi from './pizzaKioskModules/auth';
import SalePointApi from './pizzaKioskModules/salePoints';
import ProductApi from './pizzaKioskModules/products';
import ProductCategoryApi from './pizzaKioskModules/products/categories';
import ProductVariantApi from './pizzaKioskModules/products/variants';
import ProductVariantGroupApi from './pizzaKioskModules/products/variant-groups';
import LanguageApi from './pizzaKioskModules/languages';
import UserApi from './pizzaKioskModules/user';
import StaticPageTemplateApi from './pizzaKioskModules/staticPageTemplates';
import OrderApi from './pizzaKioskModules/orders';
import SystemTranslationsApi from './pizzaKioskModules/translations';
import LogsApi from './pizzaKioskModules/logs';
import CampaignApi from './pizzaKioskModules/campaigns';
import { isString } from 'lodash';

class PizzaKioskAPI extends API {
  public auth: AuthApi;

  public salePoint: SalePointApi;

  public language: LanguageApi;

  public product: ProductApi;

  public productCategory: ProductCategoryApi;

  public productVariant: ProductVariantApi;

  public productVariantGroup: ProductVariantGroupApi;

  public user: UserApi;

  public staticPage: StaticPageTemplateApi;

  public order: OrderApi;

  public translation: SystemTranslationsApi;

  public logs: LogsApi;

  public campaign: CampaignApi;

  constructor() {
    super(`${process.env.REACT_APP_PIZZAKIOSK_API_URI}/api/private`);

    this.auth = new AuthApi(this.instance);
    this.salePoint = new SalePointApi(this.instance);
    this.language = new LanguageApi(this.instance);
    this.product = new ProductApi(this.instance);
    this.productCategory = new ProductCategoryApi(this.instance);
    this.productVariant = new ProductVariantApi(this.instance);
    this.productVariantGroup = new ProductVariantGroupApi(this.instance);
    this.user = new UserApi(this.instance);
    this.staticPage = new StaticPageTemplateApi(this.instance);
    this.order = new OrderApi(this.instance);
    this.translation = new SystemTranslationsApi(this.instance);
    this.logs = new LogsApi(this.instance);
    this.campaign = new CampaignApi(this.instance);
  }

  public onRequest(request: AxiosRequestConfig) {
    const accessToken = window.localStorage.getItem('accessToken');
    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }
    return request;
  }

  public onRequestResponseError(error: AxiosError) {
    const { response } = error;
    if (response && response.data) {
      const { error: errorMessage } = response.data;
      let errorMes = errorMessage || isString(response.data) ? response.data : i18n.t('Error');
      if (response.status === 401) errorMes = i18n.t('Error401');
      toast.error(errorMes);
      console.error(errorMes);
    }
    return Promise.reject(error);
  }

  public initApi() {
    this.configureInterceptors();
  }
}

const pizzaKioskAPI = new PizzaKioskAPI();
pizzaKioskAPI.initApi();

// Make the instance debuggable
if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
  (window as any).API = pizzaKioskAPI;
}

export default pizzaKioskAPI;
