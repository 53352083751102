import {
  SystemTranslationDto,
  SystemTranslationCreateDto,
  SystemTranslationUpdateDto,
  SystemTranslationsDeleteByKeyDto,
} from '../../../types/generated';

import BaseCrudApiModule from '../../baseCrud';

class SystemTranslationsApi extends BaseCrudApiModule<
  SystemTranslationDto,
  SystemTranslationCreateDto,
  SystemTranslationUpdateDto
> {
  protected baseRoute = '/system-translations';

  public setTranslations(data: SystemTranslationCreateDto[] | SystemTranslationUpdateDto[]) {
    return this._post(this.baseRoute, data);
  }

  public deleteByKey(data: SystemTranslationsDeleteByKeyDto) {
    return this._delete(this.baseRoute, data);
  }
}

export default SystemTranslationsApi;
