import React, { FC, useEffect } from 'react';
import { useRoutes, useLocation, matchRoutes, createRoutesFromChildren, useNavigationType } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { wrapUseRoutes } from '@sentry/react';
import { Box, Button, CssBaseline, ThemeProvider, Typography } from '@material-ui/core';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import SettingsDrawer from './components/SettingsDrawer';
import SplashScreen from './components/SplashScreen';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import routes from './routes';
import { createCustomTheme } from './theme';
import './i18n';
import './numeral';

const isLocal = process.env.NODE_ENV === 'development';

if (!isLocal) {
  const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: isDev ? 0.5 : 0.2,
  });
}

const useRoutesHook = isLocal ? useRoutes : wrapUseRoutes(useRoutes);

const App: FC = () => {
  const content = useRoutesHook(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  const { t } = useTranslation();

  useScrollReset();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster position="top-center" />
      <SettingsDrawer />
      <Sentry.ErrorBoundary
        fallback={
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Box>
              <Typography color="textPrimary" variant="h6" align="center">
                {t('Something went wrong')}
              </Typography>
              <Box sx={{ textAlign: 'center', m: 1 }}>
                <Button
                  onClick={() => window.open('/')}
                  color="primary"
                  variant="contained"
                >
                  {t('Reload application')}
                </Button>
              </Box>
            </Box>
          </Box>
        }
      >
        {auth.isInitialized ? content : <SplashScreen />}
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
