import {
  ProductImageDto,
  ProductTranslationDto,
  ProductJoinInfoUpdateDto,
  ProductJoinInfoCreateDto,
  ProductJoinInfoDto,
  ProductTranslationUpdateDto,
  ProductTranslationCreateDto,
  ProductRowCategoryDto,
  ProductRowVariantDto,
  ProductRowVariantCreateDto,
  ProductComponentDto,
  ProductComponentJoinInfoDto,
  ProductRowVariantJoinInfoDto,
} from '../../../types/generated';

import BaseCrudApiModule from 'src/api/baseCrud';

class ProductApi extends BaseCrudApiModule<ProductJoinInfoDto, ProductJoinInfoCreateDto, ProductJoinInfoUpdateDto> {
  protected baseRoute = '/products';

  public getTranslations(id: number) {
    return this._get<ProductTranslationDto[]>(`${this.baseRoute}/${id}/translations`);
  }

  public setTranslations(translations: (ProductTranslationUpdateDto | ProductTranslationCreateDto)[]) {
    return this._post<ProductTranslationDto[]>('product-translations', translations);
  }

  public getImages(productId: number) {
    return this._get<ProductImageDto[]>(`${this.baseRoute}/${productId}/images`);
  }

  public setImages(productId: number, files: File[]) {
    const formData = new FormData();
    files.forEach((x) => {
      formData.append('images', x);
    });
    formData.set('productId', productId.toString());
    return this._post('product-images', formData);
  }

  public deleteImage(productImageId: string) {
    return this._delete(`product-images/${productImageId}`);
  }

  public getCategories(productId: number) {
    return this._get<ProductRowCategoryDto[]>(`${this.baseRoute}/${productId}/categories`);
  }

  public createCategories(categories: ProductRowCategoryDto[]) {
    return this._post<ProductRowCategoryDto[]>('product-row-categories', categories);
  }

  public updateCategories(categories: ProductRowCategoryDto[]) {
    return this._patch<ProductRowCategoryDto[]>('product-row-categories', categories);
  }

  public deleteCategories(productId: number) {
    return this._delete<ProductRowCategoryDto[]>(`${this.baseRoute}/${productId}/categories`);
  }

  public getComponents(productId: number) {
    return this._get<ProductComponentJoinInfoDto[]>(`${this.baseRoute}/${productId}/components`);
  }

  public createComponents(components: ProductComponentDto[]) {
    return this._post<ProductComponentJoinInfoDto[]>('product-components', components);
  }

  public deleteComponent(productId: number, componentId: number) {
    return this._delete<ProductComponentDto[]>(`${this.baseRoute}/${productId}/components/${componentId}`);
  }

  public getVariants(productId: number) {
    return this._get<ProductRowVariantJoinInfoDto[]>(`${this.baseRoute}/${productId}/variants`);
  }

  public setVariants(variants: ProductRowVariantCreateDto[]) {
    return this._post<ProductRowVariantDto[]>('product-row-variants', variants);
  }

  public deleteVariant(productId: number, variantId: number) {
    return this._delete<ProductRowVariantDto[]>(`${this.baseRoute}/${productId}/variants/${variantId}`);
  }
}

export default ProductApi;
