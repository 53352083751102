import BaseApiModule, { IPaginationOptions } from './base';

export default abstract class BaseCrudApiModule<T, TCreateModel = T, TUpdateModel = T> extends BaseApiModule {
  protected abstract baseRoute: string;

  list(options?: IPaginationOptions) {
    return this.pagedRequest<T>(this.baseRoute, options);
  }

  create(model: TCreateModel) {
    return this._post<T>(this.baseRoute, model);
  }

  getAll() {
    return this._get<T[]>(this.baseRoute);
  }

  get(id?: string | number) {
    if (typeof id === 'undefined') {
      return this._get<T>(this.baseRoute);
    }
    return this._get<T>(`${this.baseRoute}/${id}`);
  }

  update(id: string | number, model: TUpdateModel) {
    return this._patch<T>(`${this.baseRoute}/${id}`, model);
  }

  delete(id: string | number) {
    return this._delete<any>(`${this.baseRoute}/${id}`);
  }
}
