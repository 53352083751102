import numeral from 'numeral';

numeral.register('locale', 'et', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal() {
    return '';
  },
  currency: {
    symbol: '€',
  },
});
numeral.locale('et');

export default numeral;
