import {
  StaticPageTemplateTranslationDto,
  StaticPageTemplateJoinInfoDto,
  StaticPageTemplateJoinInfoCreateDto,
  StaticPageTemplateJoinInfoUpdateDto,
  StaticPageTemplateTranslationCreateDto,
  StaticPageTemplateTranslationUpdateDto,
} from '../../../types/generated';
import BaseCrudApiModule from '../../baseCrud';

class StaticPageTemplateApi extends BaseCrudApiModule<
  StaticPageTemplateJoinInfoDto,
  StaticPageTemplateJoinInfoCreateDto,
  StaticPageTemplateJoinInfoUpdateDto
> {
  protected baseRoute = '/static-pages';

  public getTranslations(id: string | number) {
    return this._get<StaticPageTemplateTranslationDto[]>(`${this.baseRoute}/${id}/translations`);
  }

  public setTranslations(
    translations: (StaticPageTemplateTranslationCreateDto | StaticPageTemplateTranslationUpdateDto)[]
  ) {
    return this._post<StaticPageTemplateTranslationDto[]>('static-pages-translations', translations);
  }
}

export default StaticPageTemplateApi;
