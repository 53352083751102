import { useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Typography } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useAuth from '../../hooks/useAuth';
import AssigmentIcon from '@material-ui/icons/Assignment';
import UserIcon from '../../icons/User';
import StoreIcon from '@material-ui/icons/Store';
import LocalPizzaIcon from '@material-ui/icons/LocalPizza';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import CampaignIcon from '@material-ui/icons/Campaign';
import LanguageIcon from '@material-ui/icons/Language';
import PersonIcon from '@material-ui/icons/Person';
import PostAddIcon from '@material-ui/icons/PostAdd';
import TocIcon from '@material-ui/icons/Toc';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { useTranslation } from 'react-i18next';
import { UserRoleEnum } from 'src/types/interfaces';
import { compact } from 'lodash';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const { t } = useTranslation();

  const sections = useMemo(
    () => [
      {
        title: 'General',
        items: [
          {
            title: 'Account',
            path: '/dashboard/account',
            icon: <UserIcon fontSize="small" />,
          },
        ],
      },
      {
        title: 'Management',
        items: compact([
          user.role === UserRoleEnum.Admin && {
            title: 'Users',
            path: '/dashboard/users',
            icon: <PersonIcon fontSize="small" />,
            children: [
              {
                title: 'List',
                path: '/dashboard/users',
              },
              {
                title: 'Create',
                path: '/dashboard/users/new',
              },
            ],
          },
          {
            title: 'Sale points',
            path: '/dashboard/salepoints',
            icon: <StoreIcon fontSize="small" />,
            children: compact([
              {
                title: 'List',
                path: '/dashboard/salepoints',
              },
              user.role === UserRoleEnum.Admin && {
                title: 'Create',
                path: '/dashboard/salepoints/new',
              },
            ]),
          },
          (user.role === UserRoleEnum.Admin || user.role === UserRoleEnum.FranchiseManager) && {
            title: 'Products',
            path: '/dashboard/products',
            icon: <LocalPizzaIcon fontSize="small" />,
            children: compact([
              user.role === UserRoleEnum.Admin && {
                title: 'List',
                path: '/dashboard/products',
              },
              user.role === UserRoleEnum.Admin && {
                title: 'Create',
                path: '/dashboard/products/new',
              },
              user.role === UserRoleEnum.Admin && {
                title: 'Categories',
                path: '/dashboard/product-categories',
              },
              user.role === UserRoleEnum.Admin && {
                title: 'Variants',
                path: '/dashboard/product-variants',
              },
              {
                title: 'Prices',
                path: '/dashboard/product-prices',
              },
            ]),
          },
          user.role === UserRoleEnum.Admin && {
            title: 'Static pages',
            path: '/dashboard/static-pages',
            icon: <PostAddIcon fontSize="small" />,
            children: [
              {
                title: 'List',
                path: '/dashboard/static-pages',
              },
              {
                title: 'Create',
                path: '/dashboard/static-pages/new',
              },
            ],
          },
          {
            title: 'Orders',
            path: '/dashboard/orders',
            icon: <TocIcon fontSize="small" />,
            children: [
              {
                title: 'List',
                path: '/dashboard/orders',
              },
            ],
          },
          user.role === UserRoleEnum.Admin && {
            title: 'Translations',
            path: '/dashboard/system-translations',
            icon: <GTranslateIcon fontSize="small" />,
            children: [
              {
                title: 'List',
                path: '/dashboard/system-translations',
              },
              {
                title: 'Create',
                path: '/dashboard/system-translations/new',
              },
            ],
          },
          user.role === UserRoleEnum.Admin && {
            title: 'Languages',
            path: '/dashboard/languages',
            icon: <LanguageIcon fontSize="small" />,
            children: [
              {
                title: 'List',
                path: '/dashboard/languages',
              },
              {
                title: 'Create',
                path: '/dashboard/languages/new',
              },
            ],
          },
          user.role === UserRoleEnum.Admin && {
            title: 'Logs',
            path: '/dashboard/logs',
            icon: <AssigmentIcon fontSize="small" />,
            children: [
              {
                title: 'List',
                path: '/dashboard/logs',
              },
            ],
          },
          user.role === UserRoleEnum.Admin && {
            title: 'Campaigns',
            path: '/dashboard/campaigns',
            icon: <CampaignIcon fontSize="small" />,
            children: [
              {
                title: 'List',
                path: '/dashboard/campaigns',
              },
              {
                title: 'Create',
                path: '/dashboard/campaigns/new',
              },
            ],
          },
        ]),
      },
    ],
    [user.role]
  );

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
            justifyContent: 'center',
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              {user.firstName && user.lastName ? (
                <Typography color="textPrimary" variant="subtitle2">
                  {`${user.firstName} ${user.lastName}`}
                </Typography>
              ) : (
                <Typography color="textPrimary" variant="subtitle2">
                  {user.email}
                </Typography>
              )}
              {user.role && (
                <>
                  <Typography color="textSecondary" variant="body2">
                    {t('Your role')}:
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {t(user.role)}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
