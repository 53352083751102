import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { IApiFilter, IApiPage, IApiSort, IPagedResult } from 'src/types/interfaces';
import { serializeFilters, serializePagination, serializeSorts } from './utils';

export interface IRequestParameters extends AxiosRequestConfig {
  [key: string]: any;
}
export interface IPaginationOptions {
  page?: IApiPage;
  sorts?: IApiSort[];
  filters?: IApiFilter[];
  /** Any extra query parameters */
  params?: IRequestParameters['params'];
}

export default abstract class BaseApiModule {
  protected instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  protected async pagedRequest<T>(url: string, options: IPaginationOptions = {}): Promise<IPagedResult<T>> {
    const requestParams: IRequestParameters = {
      ...serializePagination(options.page),
      ...serializeSorts(options.sorts),
      ...serializeFilters(options.filters),
      ...options.params,
    } as IRequestParameters;
    const request = await this.instance.request<T[]>({ url, method: 'GET', params: requestParams });
    const total = parseInt(request.headers['x-total-count'], 10);
    return {
      items: request.data,
      total: total || null,
    };
  }

  protected async _get<T = any>(uri: string, config?: AxiosRequestConfig) {
    return this.request<T>(uri, 'GET', config);
  }

  protected async _post<T = any>(uri: string, data?: any, config?: AxiosRequestConfig) {
    return this.request<T>(uri, 'POST', this.mergeConfigData(data, config));
  }

  protected async _patch<T = any>(uri: string, data?: any, config?: AxiosRequestConfig) {
    return this.request<T>(uri, 'PATCH', this.mergeConfigData(data, config));
  }

  protected async _delete<T = any>(uri: string, data?: any, config?: AxiosRequestConfig) {
    return this.request<T>(uri, 'DELETE', this.mergeConfigData(data, config));
  }

  protected async request<T = any>(url: string, method: string, config?: AxiosRequestConfig) {
    // Overwrite the request config url/method properties with the supplied parameter values
    const configCopy = { ...(config || {}) };
    const mergedConfig = Object.assign(configCopy, { url, method });

    // Return unwrapped response data
    const response = await this.instance.request<T>(mergedConfig);
    return response.data;
  }

  /**
   * Creates a new request config with the `data` value replaced
   * @param data request body
   * @param config axios request config
   */
  private mergeConfigData(data: any, config?: AxiosRequestConfig): AxiosRequestConfig {
    const configCopy = { ...(config || {}) };
    return Object.assign(configCopy, { data });
  }

  /** Method that is used to make get request and download returned file */
  protected async downloadFile(url: string, filename: string) {
    try {
      const data = await this._get(url, { responseType: 'blob' });
      const href = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = href;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.error('downloadFile failed: ', error);
    }
  }

  protected async shareFile(url: string, filename: string, fileType: string) {
    try {
      const data = await this._get(url, { responseType: 'blob' });
      const file = new File([data], filename, { type: fileType });
      if (window.navigator.canShare({ files: [file] }) && window.navigator.share) {
        await window.navigator.share({
          title: filename,
          text: filename,
          files: [file],
        });
      }
    } catch (error) {
      console.error('shareFile failed: ', error);
    }
  }
}
