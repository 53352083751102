import BaseApiModule, { IPaginationOptions } from 'src/api/base';
import { LogDto } from 'src/types/generated';

class LogsApi extends BaseApiModule {
  protected baseRoute = '/logs';

  public getLogs(options?: IPaginationOptions) {
    return this.pagedRequest<LogDto>(this.baseRoute, options);
  }
}

export default LogsApi;
