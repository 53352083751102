import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import toast from 'react-hot-toast';
import { isString } from 'lodash';

class API {
  protected instance: AxiosInstance;

  constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  public configureInterceptors() {
    this.instance.interceptors.request.use(
      (request: AxiosRequestConfig) => this.onRequest(request),
      (error: AxiosError) => this.onRequestResponseError(error)
    );

    this.instance.interceptors.response.use(undefined, (error: AxiosError) => this.onRequestResponseError(error));
  }

  public onRequest(request: AxiosRequestConfig) {
    return request;
  }

  public onRequestResponseError(error: AxiosError) {
    const { response } = error;
    if (response && response.data) {
      const { error: errorMessage } = response.data;
      toast.error(errorMessage || isString(response.data) ? response.data : 'Request failed');
      console.error(errorMessage || isString(response.data) ? response.data : 'Request failed');
    }
    return Promise.reject(error);
  }
}

export default API;
