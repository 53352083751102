import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { GridFilterModel, GridSortModel } from '@material-ui/data-grid';

export interface ITableSettings {
    sort: GridSortModel;
    filter: GridFilterModel;
    pageSize: number;
    pageNumber: number;
    name: string;
}

interface ITableSettingsState {
    settings: ITableSettings[];
}

const initialState: ITableSettingsState = {
    settings: []
};

const slice = createSlice({
    name: 'tableSort',
    initialState,
    reducers: {
        setTableSettings(state: ITableSettingsState, action: PayloadAction<ITableSettings>) {
            const currentSettings = state.settings.some((settings) => settings.name === action.payload.name);
            if (!currentSettings) {
                state.settings = [...state.settings, action.payload];
            } else {
                const removedSettingsState = state.settings.filter((settings) => settings.name !== action.payload.name);
                state.settings = [...removedSettingsState, action.payload];
            }
        }
    }
});

export const { reducer } = slice;

export const setTableSettings =
    (settings: ITableSettings): AppThunk =>
    async (dispatch): Promise<void> => {
    dispatch(slice.actions.setTableSettings(settings));
};

export default slice;
