import {
  UserDto,
  UserCreateDto,
  UserUpdateDto,
  UserSalepointDto,
} from '../../../types/generated';
import BaseCrudApiModule from 'src/api/baseCrud';

class UserApi extends BaseCrudApiModule<UserDto, UserCreateDto, UserUpdateDto> {
  protected baseRoute = '/users';

  public getSalepoints(userId: string | number) {
    return this._get<UserSalepointDto[]>(`${this.baseRoute}/${userId}/salepoints`);
  }

  public deleteSalepoints(userId: number | string) {
    return this._delete<UserSalepointDto[]>(`${this.baseRoute}/${userId}/salepoints`);
  }

  public createSalepoints(salepoints: UserSalepointDto[]) {
    return this._post<UserSalepointDto[]>('user-salepoints', salepoints);
  }

  public updateSalepoints(salepoints: UserSalepointDto[]) {
    return this._patch<UserSalepointDto[]>('user-salepoints', salepoints);
  }
}

export default UserApi;
