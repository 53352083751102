import type { FC } from 'react';
import { Box, Container, Typography } from '@material-ui/core';

const AuthBanner: FC = () => (
  <Box
    sx={{
      backgroundColor: 'background.paper',
      borderBottom: 2,
      borderColor: 'divider',
      py: 2,
    }}
  >
    <Container maxWidth="md">
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography color="textPrimary" variant="h6">
          Pizzakiosk admin dashboard
        </Typography>
      </Box>
    </Container>
  </Box>
);

export default AuthBanner;
