import { IPaginationOptions } from 'src/api/base';

export enum UserRoleEnum {
  Admin = 'admin',
  FranchiseManager = 'franchise_manager',
  SalePointManager = 'sale_point_manager',
}

export enum StatusEnum {
  Active = 'active',
  Inactive = 'inactive',
}
export interface IApiPage {
  size?: number;
  number?: number;
}
export interface IApiFilter {
  field: string;
  value: string;
  operator: string;
}
export interface IApiSort {
  field: string;
  ascending: boolean;
}
export interface IPagedResult<T> {
  items: T[];
  total: number;
}
export interface IDataSource<TModel, TEntity = TModel> {
  list?: (options: IPaginationOptions) => Promise<IPagedResult<TEntity>>;
}

export enum CampaignImageType {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

export enum CampaignType {
  StaticPage = 'static_page',
  Discount = 'discount',
  Combo = 'combo',
  Url = 'url',
  Category = 'category',
}

export enum CampaignUrlTarget {
  Self = 'self',
  New = 'new',
}

export enum FileAction {
  Download = 'download',
  Share = 'share',
}
