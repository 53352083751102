import BaseApiModule from 'src/api/base';
import { AuthPrivateRequestDto, UpdateUserPasswordDto, UserDto } from 'src/types/generated';

class AuthApi extends BaseApiModule {
  protected baseRoute = '/auth';

  register(model: AuthPrivateRequestDto) {
    return this._post(`${this.baseRoute}/register`, model);
  }

  login(model: AuthPrivateRequestDto) {
    return this._post(`${this.baseRoute}/login`, model);
  }

  me() {
    return this._get(`${this.baseRoute}/me`);
  }

  resetPassword(data: UpdateUserPasswordDto) {
    return this._post<UserDto>(`${this.baseRoute}/reset-password`, data);
  }
}

export default AuthApi;
