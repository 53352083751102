import type { RouteObject } from 'react-router';
import { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import DashboardLayout from './components/dashboard/DashboardLayout';
import RoleGuard from './components/RoleGuard';
import { UserRoleEnum } from './types/interfaces';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));

// Dashboard pages
const Home = Loadable(lazy(() => import('./pages/dashboard/Home')));
const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const SalepointNew = Loadable(lazy(() => import('./pages/dashboard/salepoints/SalepointNew')));
const SalepointDetails = Loadable(lazy(() => import('./pages/dashboard/salepoints/SalepointDetails')));
const SalePointsList = Loadable(lazy(() => import('./pages/dashboard/SalePointList')));

const ProductNew = Loadable(lazy(() => import('./pages/dashboard/products/ProductNew')));
const ProductDetails = Loadable(lazy(() => import('./pages/dashboard/products/ProductDetails')));
const ProductList = Loadable(lazy(() => import('./pages/dashboard/products/ProductList')));

const ProductCategoryList = Loadable(lazy(() => import('./pages/dashboard/products/categories/ProductCategoryList')));
const ProductVariantList = Loadable(lazy(() => import('./pages/dashboard/products/variants/ProductVariantList')));
const ProductsPrices = Loadable(lazy(() => import('./pages/dashboard/products/prices/ProductsPrices')));

const UserNew = Loadable(lazy(() => import('./pages/dashboard/users/UserNew')));
const UserDetails = Loadable(lazy(() => import('./pages/dashboard/users/UserDetails')));
const UserList = Loadable(lazy(() => import('./pages/dashboard/users/UserList')));

const StaticPagesNew = Loadable(lazy(() => import('./pages/dashboard/staticPages/StaticPagesNew')));
const StaticPagesDetails = Loadable(lazy(() => import('./pages/dashboard/staticPages/StaticPagesDetails')));
const StaticPagesList = Loadable(lazy(() => import('./pages/dashboard/staticPages/StaticPagesList')));

const OrdersList = Loadable(lazy(() => import('./pages/dashboard/orders/OrdersList')));
const OrderDetails = Loadable(lazy(() => import('./pages/dashboard/orders/OrderDetails')));

const TranslationsList = Loadable(lazy(() => import('./pages/dashboard/translations/TranslationsList')));
const TranslationsCreate = Loadable(lazy(() => import('./pages/dashboard/translations/TranslationsCreate')));

const LogsList = Loadable(lazy(() => import('./pages/dashboard/logs/LogsList')));

const LanguagesList = Loadable(lazy(() => import('./pages/dashboard/languages/LanguagesList')));
const LanguageNew = Loadable(lazy(() => import('./pages/dashboard/languages/LanguageNew')));
const LanguageDetails = Loadable(lazy(() => import('./pages/dashboard/languages/LanguageDetails')));

const CampaignList = Loadable(lazy(() => import('./pages/dashboard/campaigns/CampaignList')));
const CampaignNew = Loadable(lazy(() => import('./pages/dashboard/campaigns/CampaignNew')));
const CampaignDetails = Loadable(lazy(() => import('./pages/dashboard/campaigns/CampaignDetails')));

const routes: RouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
    ],
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            element: (
              <RoleGuard roles={[UserRoleEnum.Admin]}>
                <UserList />
              </RoleGuard>
            ),
          },
          {
            path: 'new',
            element: (
              <RoleGuard roles={[UserRoleEnum.Admin]}>
                <UserNew />
              </RoleGuard>
            ),
          },
          {
            path: ':id',
            element: (
              <RoleGuard roles={[UserRoleEnum.Admin]}>
                <UserDetails />
              </RoleGuard>
            ),
          },
        ],
      },
      {
        path: 'salepoints',
        children: [
          {
            path: '',
            element: (
              <RoleGuard roles={[UserRoleEnum.Admin, UserRoleEnum.FranchiseManager, UserRoleEnum.SalePointManager]}>
                <SalePointsList />
              </RoleGuard>
            ),
          },
          {
            path: 'new',
            element: (
              <RoleGuard roles={[UserRoleEnum.Admin]}>
                <SalepointNew />
              </RoleGuard>
            ),
          },
          {
            path: ':id',
            element: (
              <RoleGuard roles={[UserRoleEnum.Admin, UserRoleEnum.FranchiseManager, UserRoleEnum.SalePointManager]}>
                <SalepointDetails />
              </RoleGuard>
            ),
          },
        ],
      },
      {
        path: 'products',
        children: [
          {
            path: '',
            element: (
              <RoleGuard roles={[UserRoleEnum.Admin]}>
                <ProductList />
              </RoleGuard>
            ),
          },
          {
            path: 'new',
            element: (
              <RoleGuard roles={[UserRoleEnum.Admin]}>
                <ProductNew />
              </RoleGuard>
            ),
          },
          {
            path: ':id',
            element: (
              <RoleGuard roles={[UserRoleEnum.Admin]}>
                <ProductDetails />
              </RoleGuard>
            ),
          },
        ],
      },
      {
        path: 'product-categories',
        element: (
          <RoleGuard roles={[UserRoleEnum.Admin]}>
            <ProductCategoryList />
          </RoleGuard>
        ),
      },
      {
        path: 'product-prices',
        element: (
          <RoleGuard roles={[UserRoleEnum.Admin, UserRoleEnum.FranchiseManager]}>
            <ProductsPrices />
          </RoleGuard>
        ),
      },
      {
        path: 'static-pages',
        children: [
          {
            path: '',
            element: (
              <RoleGuard roles={[UserRoleEnum.Admin]}>
                <StaticPagesList />
              </RoleGuard>
            ),
          },
          {
            path: 'new',
            element: (
              <RoleGuard roles={[UserRoleEnum.Admin]}>
                <StaticPagesNew />
              </RoleGuard>
            ),
          },
          {
            path: ':id',
            element: (
              <RoleGuard roles={[UserRoleEnum.Admin]}>
                <StaticPagesDetails />
              </RoleGuard>
            ),
          },
        ],
      },
      {
        path: 'product-variants',
        element: (
          <RoleGuard roles={[UserRoleEnum.Admin]}>
            <ProductVariantList />
          </RoleGuard>
        ),
      },
      {
        path: 'orders',
        children: [
          {
            path: '',
            element: <OrdersList />,
          },
          {
            path: ':id',
            element: <OrderDetails />,
          },
        ],
      },
      {
        path: 'system-translations',
        element: (
          <RoleGuard roles={[UserRoleEnum.Admin]}>
            <TranslationsList />
          </RoleGuard>
        ),
      },
      {
        path: 'system-translations/new',
        element: (
          <RoleGuard roles={[UserRoleEnum.Admin]}>
            <TranslationsCreate />
          </RoleGuard>
        ),
      },
      {
        path: 'logs',
        element: (
          <RoleGuard roles={[UserRoleEnum.Admin]}>
            <LogsList />
          </RoleGuard>
        ),
      },
      {
        path: 'languages',
        element: (
          <RoleGuard roles={[UserRoleEnum.Admin]}>
            <LanguagesList />
          </RoleGuard>
        ),
      },
      {
        path: 'languages/new',
        element: (
          <RoleGuard roles={[UserRoleEnum.Admin]}>
            <LanguageNew />
          </RoleGuard>
        ),
      },
      {
        path: 'languages/:id',
        element: (
          <RoleGuard roles={[UserRoleEnum.Admin]}>
            <LanguageDetails />
          </RoleGuard>
        ),
      },
      {
        path: 'campaigns',
        element: (
          <RoleGuard roles={[UserRoleEnum.Admin]}>
            <CampaignList />
          </RoleGuard>
        ),
      },
      {
        path: 'campaigns/new',
        element: (
          <RoleGuard roles={[UserRoleEnum.Admin]}>
            <CampaignNew />
          </RoleGuard>
        ),
      },
      {
        path: 'campaigns/:id',
        element: (
          <RoleGuard roles={[UserRoleEnum.Admin]}>
            <CampaignDetails />
          </RoleGuard>
        ),
      },
    ],
  },
  {
    path: '*',
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <DashboardLayout>
              <Home />
            </DashboardLayout>
          </AuthGuard>
        ),
      },
    ],
  },
];

export default routes;
